<template></template>

<script>
export default {
  created() {
    this.$router.replace({
      path: "/recruit-detail",
      query: {
        id: this.$route.query.id,
      },
    });
  },
};
</script>

<style>
</style>